/* eslint-disable import/prefer-default-export */

// TODO: Once we upgrade to newest TypeScript with Nullish coalescing, we can rewrite if (!sjekkpunkt) return null;

import { createSelector } from 'reselect';
import { AppState } from 'store';
import { getAllBuildings } from 'store/tilstandsrapportEntities/tilstandsrapport/selectors';
import { Sjekkpunkt } from './types';

export const getSjekkpunkt = (state: AppState, id: string): Sjekkpunkt =>
  state.tilstandsrapportEntities.sjekkpunkter[id];

export const getSjekkpunktExist = createSelector(
  [getSjekkpunkt],
  sjekkpunkt => {
    return !!sjekkpunkt;
  },
);

const getUtbedringData = createSelector(
  [getSjekkpunkt],
  sjekkpunkt => {
    if (!sjekkpunkt) return null;
    return sjekkpunkt.utbedringData;
  },
);

export const getTgValue = createSelector(
  [getSjekkpunkt],
  sjekkpunkt => {
    if (!sjekkpunkt) return null;
    return sjekkpunkt.tg;
  },
);

export const getGrupper = createSelector(
  [getSjekkpunkt],
  sjekkpunkt => {
    if (!sjekkpunkt) return null;
    return sjekkpunkt.grupper;
  },
);

export const getCategory = createSelector(
  [getSjekkpunkt],
  sjekkpunkt => {
    if (!sjekkpunkt) return null;
    return sjekkpunkt.kategori;
  },
);

export const getSubCategory = createSelector(
  (state, sjekkpunktId, _tilstandsrapportId) => getSjekkpunkt(state, sjekkpunktId),
  (state, sjekkpunktId, _tilstandsrapportId) => getGrupper(state, sjekkpunktId),
  (state, _sjekkpunktId, tilstandsrapportId) => getAllBuildings(state, tilstandsrapportId),
  (sjekkpunkt, grupper, buildings) => {
    if (!sjekkpunkt) return null;
    const subCategory = sjekkpunkt.underkategori;
    return buildings && buildings.length > 1 ? `${grupper[0]}: ${subCategory}` : subCategory;
  },
);

export const getVurdering = createSelector(
  [getSjekkpunkt],
  sjekkpunkt => {
    if (!sjekkpunkt) return null;
    return sjekkpunkt.vurdering;
  },
);

export const getBeskrivelse = createSelector(
  [getSjekkpunkt],
  sjekkpunkt => {
    if (!sjekkpunkt) return null;
    return sjekkpunkt.beskrivelse;
  },
);

export const getIsCritical = createSelector(
  [getUtbedringData],
  utbedringData => {
    return utbedringData && utbedringData.critical ? utbedringData.critical : false;
  },
);

export const getUtbedringTime = createSelector(
  [getUtbedringData],
  utbedringData => {
    return utbedringData && utbedringData.when ? utbedringData.when : null;
  },
);

export const getCostDetails = createSelector(
  [getUtbedringData],
  utbedringData => {
    return utbedringData && utbedringData.costDetails ? utbedringData.costDetails : null;
  },
);

export const getConditions = createSelector(
  [getUtbedringData],
  utbedringData => {
    return utbedringData && utbedringData.conditions ? utbedringData.conditions : null;
  },
);

export const getRecommendations = createSelector(
  [getUtbedringData],
  utbedringData => {
    return utbedringData && utbedringData.recommendations ? utbedringData.recommendations : null;
  },
);

export const hasUtbedringData = createSelector(
  [getUtbedringData],
  utbedringData => {
    return (
      (utbedringData && utbedringData.costDetails ? utbedringData.costDetails : null) ||
      (utbedringData && utbedringData.conditions ? utbedringData.conditions : null) ||
      (utbedringData && utbedringData.recommendations ? utbedringData.recommendations : null)
    );
  },
);

export const getFullyQualifiedKategori = createSelector(
  [getGrupper, getCategory],
  (grupper, kategori) => {
    // prettier-ignore
    const lastIndex = (!grupper || grupper.length === 0) ? 0 : grupper.map(g => {
      return g && kategori && g.toLowerCase().trim().indexOf(kategori.toLowerCase()) < 0;
    }).lastIndexOf(true);
    return lastIndex > 0 ? `${kategori} - ${grupper[lastIndex]}` : kategori;
  },
);

export const getFloor = createSelector(
  [getGrupper, getCategory],
  (grupper, kategori) => {
    // prettier-ignore
    const lastIndex = (!grupper || grupper.length === 0) ? 0 : grupper.map(g => {
      return g && g.toLowerCase().trim().indexOf(kategori.toLowerCase()) < 0;
    }).lastIndexOf(true);
    return lastIndex > 0 ? grupper[lastIndex] : null;
  },
);

export const getKeywords = createSelector(
  [getUtbedringData],
  utbedringData => (utbedringData && utbedringData.keywords) || [],
);

const TAGS_MAP = {
  1: {
    name: 'Mer alvorlig tilstandsgrad funnet i tekst',
    description:
      'Takstmann har vurdert denne bygningsdelen til TG1 (normal slitasjeskade), men har indikert TG2 (vesentlig skade/slitasje) på deler av den. Dette burde undersøkes nærmere.',
    tag: 1,
    priority: 1,
  },
  2: {
    name: 'Mer alvorlig tilstandsgrad funnet i tekst',
    description:
      'Takstmann har vurdert denne bygningsdelen til TG1 (normal slitasjeskade), men har indikert TG3 (total funksjonssvikt) på deler av den. Dette burde undersøkes nærmere.',
    tag: 2,
    priority: 3,
  },
  3: {
    name: 'Mer alvorlig tilstandsgrad funnet i tekst',
    description:
      'Takstmann har vurdert denne bygningsdelen til TG2 (vesentlig skade/slitasje), men har indikert TG3 (total funksjonssvikt) på deler av den. Dette burde undersøkes nærmere.',
    tag: 3,
    priority: 2,
  },
};

export const getSjekkpunkWorseTGDescribedInText = createSelector(
  [getSjekkpunkt],
  sjekkpunkt => {
    if (!sjekkpunkt || !sjekkpunkt.tags || sjekkpunkt.tags.length === 0) return null;
    const mappedTags = sjekkpunkt.tags.map(t => TAGS_MAP[t]);
    const priorities = mappedTags.map(mt => mt.priority);
    return mappedTags[priorities.indexOf(Math.max(...priorities))];
  },
);
